import { template as template_f22f66156e71493a8a3f866390c204b2 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f22f66156e71493a8a3f866390c204b2(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
