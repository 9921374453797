import { template as template_8f404a4d2ad54c508c5f3ea01d4d9b89 } from "@ember/template-compiler";
const FKText = template_8f404a4d2ad54c508c5f3ea01d4d9b89(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
