import { template as template_8e06bc05eb8f4d82b8592c59624193e2 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8e06bc05eb8f4d82b8592c59624193e2(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
