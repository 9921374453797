import { template as template_e39f24a7eec04b7bb745fc6c41c71972 } from "@ember/template-compiler";
const WelcomeHeader = template_e39f24a7eec04b7bb745fc6c41c71972(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
